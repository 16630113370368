import * as params from '@params';
const error1 = params.error1;
const error2 = params.error2;
const error3 = params.error3;

const success = params.success;

const incomplete = params.incomplete;

jQuery(function($) {
    "use strict";
    let $window = $(window);
    let body = $("body");
    let $root = $("html, body");

    let mailerURL = 'https://mailer.testfabrik.com/mail'; // todo from data

    function makeMessage(classes, id, message, mailtext) {
        let openingTag = `<div class="${classes}" style="padding:10px; margin-bottom:25px;" id="${id}">`;
        let content = message ? message : makeErrorMessage(mailtext)
        let closingTag = '</div>';
        return openingTag + content + closingTag;
    }

    function makeErrorMessage(mailtext) {
        let openingTag = `<a href="mailto:info@testfabrik.com?subject=Kontaktanfrage&body=${mailtext}">`;
        let closingTag = '</a>';
        return error1 + openingTag + error2 + closingTag + error3;
    }

    //contact us
    $("#submitContact").on('click', function() {
        let userName = $('#nameContact').val();
        let userEmail = $('#emailContact').val();
        let userTelephone = $('#telephoneContact').val();
        let userMessage = $('#messageContact').val();
        let userLang = localStorage.getItem("lang");
        let userHref = window.location.href;

            console.log(userName, userEmail, userTelephone, userMessage, userLang, this.id);

        let result;
        if (this.id === 'submitContact') {
            result = $('#result1');
        } else {
            result = $('#resultDemo');
        }
        //simple validation at client's end
        let postData, output;
        let proceed = true;
        if (userName === "") {
            proceed = false;
        }
        if (userEmail === "") {
            proceed = false;
        }
        if (userMessage === "") {
            proceed = false;
        }
        //everything looks good! proceed...
        if (proceed) {
            //data to be sent to server
            postData = {
                'userName': userName,
                'userEmail': userEmail,
                'userMessage': userMessage,
                'userTelephone': userTelephone,
                'userLang': userLang,
                'href': userHref,
                // todo: ids for forms
            };
            //Ajax post data to server
            console.log('sending mail');
            $.post(mailerURL + '/contactmail', postData).done(function(response) {
                //load json data from server and output message
                output = makeMessage("alert-success", "contact-success", success);
                //reset values in all input fields
                $('.getin_form input').val('');
                $('.getin_form textarea').val('');
                result.slideUp("fast").html(output).slideDown();
            }).fail(function(response) {
                console.log(response);
                output = makeMessage("alert-danger", "contact-error", response.responseText, userMessage);
                // '<div class="alert-danger" style="padding:10px; margin-bottom:25px;" id="demoError">' + (response.responseText !== "" ? response.responseText : "Es ist ein Fehler aufgetreten. Klicken Sie " + '<a style="text-decoration: underline!important" href="mailto:info@testfabrik.com?subject=Kontaktanfrage&body=' + userMessage + '">hier</a>' + ", um Ihre Anfrage aus ihrem Mailprogramm zu versenden. ") + "</div>";
                result.slideUp("fast").html(output).slideDown();
            });
        } else {
            output = makeMessage("alert-danger", "contact-incomplete", incomplete, undefined);
              // '<div class="alert-danger" style="padding:10px; margin-bottom:25px;">Bitte alle Felder ausfüllen.</div>';
            result.slideUp("fast").html(output).slideDown();
        }
    });

    $("#submitMailtest").on('click', function() {
        let result = $('#resultMailtest');
        let userEmail = $('#emailMailtest').val();

        // console.log(result);
        console.log(userEmail, this.id);
        //simple validation at client's end
        let postData, output;
        let proceed = true;
        if (userEmail === "") {
            proceed = false;
        }
        //everything looks good! proceed...
        if (proceed) {

            //data to be sent to server
            postData = {
                'userEmail': userEmail,
            };

            // Ajax post data to server
            $.post(mailerURL + '/wmmailtest', postData).done(function(response) {
                output = makeMessage("alert-success", "mailtest-success", success);
                // '<div class="alert-success" style="padding:10px; margin-bottom:25px;" id="demoSuccess">Vielen Dank für Ihre Anmeldung.</div>';
                //reset values in all input fields
                $('.getin_form input').val('');
                $('.getin_form textarea').val('');
                result.slideUp("fast").html(output).slideDown();
            }).fail(function(response) {
                output = makeMessage("alert-danger", "mailtest-error", response.responseText, undefined);
                // '<div class="alert-danger" style="padding:10px; margin-bottom:25px;" id="demoError">Ein Fehler ist aufgetreten.</div>';
                result.slideUp("fast").html(output).slideDown();
            });
        } else {
            output = makeMessage("alert-danger", "mailtest-incomplete", incomplete, undefined);
            // output = '<div class="alert-danger" style="padding:10px; margin-bottom:25px;" id="demoIncomplete">Bitte alle Felder ausfüllen.</div>';
            result.slideUp("fast").html(output).slideDown();
        }
    });

});
